<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @opened='onOpened' @close='onClose' :title='dialogTitle'
               width='80%'>
      <!--    列表-->
      <bonus-table :search-condition='searchCondition' :edit-mode='true' @getInfo='getInfo'
                   @editVal='editVal'></bonus-table>

      <!--    操作项-->
      <div style='border-top: #f2f2f2 1px solid;padding-top: 10px;margin-top: 10px'>
        <bonus-handle :ym='ym' :group_id='groupId' :current-bonus-bill='currentBonusBill' :current-data-list='dataList'
                      @saved='saved()' />
      </div>

    </el-dialog>
  </div>
</template>

<script>
import BonusTable from './BonusTable'
import BonusHandle from './BonusHandle'

export default {
  name: 'BonusEdit',
  components: {
    BonusTable, BonusHandle
  },
  props: {
    id: {
      type: [String, Number],
      default() {
        return null
      }
    }
  },
  computed: {
    dialogTitle() {
      return `奖金分配 - 【${this.currentBonusBill.group_name || ''}】${this.currentBonusBill.year || ''}年${this.currentBonusBill.month || ''}月`
    },
    groupId() {
      return this.currentBonusBill.group_id
    },
    ym() {
      return `${this.currentBonusBill.year}-${this.currentBonusBill.month}`
    }
  },
  data() {
    return {
      searchCondition: { id: null },
      currentBonusBill: {},
      dataList: []//当前表格数据
    }
  },
  methods: {
    onOpened() {
      this.searchCondition = { id: this.id }
    },
    onClose() {

    },
    getInfo(val) {
      this.currentBonusBill = val
    },
    editVal(val) {
      this.dataList = val
    },
    saved() {
      this.$emit('close')
    }
  }

}
</script>

<style scoped>

</style>
